import axios from "axios";
import store from "../store";

const APIUrl = process.env.VUE_APP_API_URL;

const axiosBase = axios.create({
  baseURL: APIUrl,
  headers: { "content-type": "application/json" },
});
const uspAPI = axios.create({
  baseURL: APIUrl,
});

// DRAGONS: Fucking don't touch until you master axios interceptors. I mean it! As it was this shit could and did intercept itself, loop into single interception and a lot of other bad shit. I tried (and seemingly succeeded as of now) to simplify this and make this more fail-proof.
// DRAGONS: I leave all debugging console.log statements in, so any brave adventurer trying to find why this broke again has at least a bit less work, but that's all I can do here with my current knowledge.

uspAPI.interceptors.response.use(undefined, async function (err) {
  // console.log("Entered interceptor!");
  // Clone original request
  const originalConfig = err.config;
  // if this is not token refreshing...
  if (originalConfig.url !== "/api/user/token/refresh/" && err.response) {
    // if error response status is 401, it means the request was invalid due to expired access token
    // This also cannot happen again if we're already retrying
    if (err.response.status === 401 && !originalConfig._retry) {
      // set that we're retrying
      originalConfig._retry = true;
      // console.log("Intercepting!");

      // try to get new token!
      await store
        // attempt to obtain new access token by running 'refreshToken' action
        .dispatch("refreshTokens");
      // console.log("Store should be refreshed!");
      originalConfig.headers = { Authorization: `Bearer ${store.state.accessToken}` };

      return axios.request(originalConfig);
    }
  }
  return Promise.reject(err);
});
export { axiosBase, uspAPI };
