<template>
  <main
    class="flex-1 relative focus:outline-none"
    tabindex="0"
    :class="{ 'overflow-hidden': spin, 'overflow-y-auto': !spin }">
    <transition
      name="custom-fade"
      enter-from-class="opacity-0"
      enter-active-class="transition-all transition-fastest ease-out-quad"
      enter-to-class="opacity-100"
      leave-from-class="opacity-100"
      leave-active-class="transition-all transition-faster ease-in-quad"
      leave-to-class="opacity-0">
      <div v-if="spin">
        <Spinner />
      </div>
    </transition>
    <slot></slot>
  </main>
</template>
<script>
import Spinner from "@/components/Spinner.vue";
export default {
  name: "SpinnerContainer",
  components: {
    Spinner,
  },
  props: {
    spin: { type: Boolean, required: true },
  },
};
</script>
