<template>
  <router-view />
</template>
<script>
export default {
  data() {
    return {};
  },
  watch: {
    "$i18n.locale": function () {
      this.updatePrimeVueLang();
    },
  },
  created() {
    const html = document.documentElement; // returns the html tag
    html.setAttribute("lang", this.$store.state.lang.slice(0, 2));
  },
  mounted() {
    this.$i18n.locale = this.$store.state.lang;
    this.updatePrimeVueLang();
    // update filter for store
    this.$store.state.filterNplate.query = JSON.parse(localStorage.getItem("filterNplate")) || {};
    this.$store.state.filterOrder.query = JSON.parse(localStorage.getItem("filterOrder")) || {};
    this.$store.state.filterRaw.query = JSON.parse(localStorage.getItem("filterRaw")) || {};
    this.$store.state.filterScrap.query = JSON.parse(localStorage.getItem("filterScrap")) || {};
  },
  methods: {
    updatePrimeVueLang() {
      this.$primevue.config.locale.dayNamesMin = [
        this.$t("calendar.Sun"),
        this.$t("calendar.Mon"),
        this.$t("calendar.Tue"),
        this.$t("calendar.Wed"),
        this.$t("calendar.Thu"),
        this.$t("calendar.Fri"),
        this.$t("calendar.Sat"),
      ];
      this.$primevue.config.locale.monthNames = [
        this.$t("calendar.January"),
        this.$t("calendar.February"),
        this.$t("calendar.March"),
        this.$t("calendar.April"),
        this.$t("calendar.May"),
        this.$t("calendar.June"),
        this.$t("calendar.July"),
        this.$t("calendar.August"),
        this.$t("calendar.September"),
        this.$t("calendar.October"),
        this.$t("calendar.November"),
        this.$t("calendar.December"),
      ];
      this.$primevue.config.locale.monthNamesShort = [
        this.$t("calendar.monthNamesShort_Jan"),
        this.$t("calendar.monthNamesShort_Feb"),
        this.$t("calendar.monthNamesShort_Mar"),
        this.$t("calendar.monthNamesShort_Apr"),
        this.$t("calendar.monthNamesShort_May"),
        this.$t("calendar.monthNamesShort_Jun"),
        this.$t("calendar.monthNamesShort_Jul"),
        this.$t("calendar.monthNamesShort_Aug"),
        this.$t("calendar.monthNamesShort_Sep"),
        this.$t("calendar.monthNamesShort_Oct"),
        this.$t("calendar.monthNamesShort_Nov"),
        this.$t("calendar.monthNamesShort_NovDec"),
      ];
      this.$primevue.config.locale.choose = this.$t("prime.choose");
      this.$primevue.config.locale.emptyMessage = this.$t("prime.emptyMessage");
      this.$primevue.config.locale.cancel = this.$t("prime.cancel");
      this.$primevue.config.locale.upload = this.$t("prime.upload");
      this.$primevue.config.locale.pending = this.$t("prime.pending");
      this.$primevue.config.locale.clear = this.$t("prime.clear");
      this.$primevue.config.locale.today = this.$t("prime.today");
      this.$primevue.config.locale.accept = this.$t("prime.accept");
      this.$primevue.config.locale.reject = this.$t("prime.reject");
      this.$primevue.config.locale.firstDayOfWeek = 1;
    },
    logout() {
      this.$store.dispatch("logoutUser").then(() => {
        this.$router.push({ name: "Home" });
      });
    },
  },
};
</script>
