import "./assets/tailwind.css";
import "./assets/primestyles.css";
import "./assets/primeicons.css";
import "./assets/VueSearchSelect.css";
import "vue-awesome-sidebar/dist/vue-awesome-sidebar.css";
import "primevue/resources/themes/saga-blue/theme.css";

import App from "./App.vue";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Column from "primevue/column";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmationService from "primevue/confirmationservice";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import MainCard from "@/components/containers/MainCard.vue";
import PrimeVue from "primevue/config";
import RouterLinkButton from "../src/components/buttons/RouterLinkButton.vue";
import SpinnerContainer from "@/components/containers/SpinnerContainer.vue";
import cardDirective from "./directives/card";
import focus from "./directives/focus";
import { createApp } from "vue";
import i18n from "./i18n";
import router from "./router";
import store from "./store";
import vueAwesomeSidebar from "vue-awesome-sidebar";

const app = createApp(App);

app
  .use(router)
  .use(store)
  .use(i18n)
  .use(PrimeVue)
  .use(ConfirmationService)
  .use(vueAwesomeSidebar)
  .component("RouterLinkButton", RouterLinkButton)
  .component("SpinnerContainer", SpinnerContainer)
  .component("MainCard", MainCard)
  .component("InputText", InputText)
  .component("Calendar", Calendar)
  .component("Dropdown", Dropdown)
  .component("Button", Button)
  .component("DataTable", DataTable)
  .component("Column", Column)
  .component("Dialog", Dialog)
  .component("ConfirmDialog", ConfirmDialog)
  .mount("#app");

app.directive("guard", cardDirective);
app.directive("focus", focus);
app.config.productionTip = false;
app.config.devtools = false;
app.config.performance = false;
