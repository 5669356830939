<template>
  <div class="h-screen flex overflow-hidden bg-gray-100">
    <ConfirmDialog></ConfirmDialog>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="relative z-20 flex-shrink-0 flex h-16 bg-white shadow">
        <UserBar />
      </div>
      <SideBarNav @onChangedWidth="changeWidthCard" />
      <router-view
        class="expanded-shrink"
        :class="{
          openSideBarMyClass: expandedPanel,
          closeSideBarMyClass: !expandedPanel,
        }" />
      <SpinnerBetter />
    </div>
  </div>
</template>
<script>
import SideBarNav from "@/components/SideBarNav.vue";
import UserBar from "@/components/UserBar.vue";
import "vue-awesome-sidebar/dist/vue-awesome-sidebar.css";
import SpinnerBetter from "@/components/SpinnerBetter.vue";

export default {
  name: "Root",
  components: {
    SideBarNav,
    UserBar,
    SpinnerBetter,
  },

  beforeRouteUpdate(to, from, next) {
    const messageCondition = from.path !== to.path;
    const pageList = [
      "OrderCreate",
      "NPlateOrderCreate",
      "NPlateOrderEdit",
      "InternalOrderCreate",
      "Add new scrap order",
      "Add new contract",
    ];
    const isOnListConfirm = pageList.includes(from.name);
    if (messageCondition && isOnListConfirm && this.$store.state.showConfirmMessage) {
      this.confirm1(next);
    } else {
      this.$store.dispatch("setConfirmMessage", true);
      next();
    }
  },
  data() {
    return {
      expandedPanel: true,
    };
  },
  computed: {
    cardSize() {
      return !this.expandedPanel ? "390px" : "50px";
    },
  },
  methods: {
    changeWidthCard(extendedPanel) {
      console.log("extendedPanel", extendedPanel);
      this.expandedPanel = extendedPanel;
    },
    confirm1(next) {
      this.$confirm.require({
        header: this.$t("actions.leaving_page_title"),
        message: this.$t("actions.leaving_page_info"),
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          next();
        },
        reject: () => {},
      });
    },
  },
};
</script>

<style>
.expanded-shrink {
  transition: margin-left 300ms ease;
}
.openSideBarMyClass {
  margin-left: 50px;
}
.closeSideBarMyClass {
  margin-left: 390px;
}
</style>
