function checkTheSameArrayValues(array1, array2) {
  // sprawdza czy obie tablice obektów są identyczne
  return (
    array1.every((value) => array2.includes(value)) &&
    array2.every((value) => array1.includes(value)) &&
    array1.length === array2.length
  );
}
function includesElements(array1, array2) {
  // zwraca wspólne obiekty
  return array1.filter((value) => array2.includes(value));
}
function excludesElements(array1, array2) {
  // zwraca obiekty które nie wspólne
  return array1.filter((value) => !array2.includes(value));
}
function removeDuplicates(array1, array2) {
  return [...new Set([...array1, ...array2])];
}
function checkAccessAllRequired(myPermission, accessPermission) {
  return accessPermission.every((permission) => myPermission.includes(permission));
}
function checkAccessOneRequired(myPermission, accessPermission) {
  return accessPermission.some((permission) => myPermission.includes(permission));
}
function convertArrayDate(arrayToConvert) {
  return arrayToConvert?.length ? arrayToConvert.map((x) => (x ? new Date(x) : null)) : [];
}

export {
  checkTheSameArrayValues,
  includesElements,
  excludesElements,
  removeDuplicates,
  checkAccessAllRequired,
  checkAccessOneRequired,
  convertArrayDate,
};
export default checkTheSameArrayValues;
