<template>
  <button
    :class="calcClasses()"
    :title="titleAttr"
    :type="buttonType"
    :disabled="disabled"
    @click="clickEvent">
    <Icon
      v-if="!iconOnRight"
      :icon-name="iconName"
      :class-attr="iconClasses" />
    {{ titleText }}
    <Icon
      v-if="iconOnRight"
      :icon-name="iconName"
      :class-attr="iconClasses" />
  </button>
</template>

<script>
import Icon from "@/components/icons/Icon.vue";
export default {
  name: "ClickableButton",
  components: {
    Icon,
  },
  props: {
    buttonType: {
      type: String,
      default: "button",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    iconOnRight: {
      type: Boolean,
      default: false,
    },
    colorText: {
      type: String,
      default: "",
    },
    colorBg: {
      type: String,
      default: "",
    },
    colorOutline: {
      type: String,
      default: "blue",
    },
    titleAttr: {
      type: String,
      default: "",
    },
    iconName: {
      type: String,
      default: "",
    },
    titleText: {
      type: String,
      default: "",
    },
    classAttr: {
      type: String,
      default: "",
    },
    clickEvent: {
      type: Function,
      default: () => {},
    },
    iconClass: {
      type: String,
      default: "w-4 h-4",
    },
  },
  computed: {
    iconClasses: function () {
      return !this.iconOnRight ? "-ml-0.5 mr-2 " + this.iconClass : "ml-2 -mr-0.5 " + this.iconClass;
    },
  },
  methods: {
    // statusName,
    // statusClasses,
    calcClasses: function () {
      if (!this.disabled) {
        return (
          "ml-0.5 mr-0.5 inline-flex items-center py-2 px-4 hover:text-" +
          this.colorText +
          " hover:border-" +
          this.colorOutline +
          "-600 hover:bg-" +
          this.colorBg +
          "-700 focus:border-" +
          this.colorOutline +
          "-400 focus:outline-" +
          this.colorOutline +
          " active:bg-" +
          this.colorBg +
          "-800 active:text-" +
          this.colorText +
          " bg-" +
          this.colorBg +
          "-600 border border-" +
          this.colorOutline +
          "-100 rounded-md text-sm font-medium text-" +
          this.colorText +
          " transition duration-150 ease-in-out focus:ring-2 focus:ring-offset-1 focus:ring-" +
          this.colorOutline +
          "-500 " +
          this.classAttr
        );
      } else {
        return (
          "ml-0.5 mr-0.5 inline-flex items-center py-2 px-4 hover:text-" +
          this.colorText +
          " hover:bg-gray-400 focus:border-" +
          this.colorOutline +
          "-400 active:bg-gray-400 active:text-" +
          this.colorText +
          " bg-gray-400 border border-" +
          this.colorOutline +
          "-100 rounded-md text-sm font-medium text-" +
          this.colorText +
          " transition duration-150 ease-in-out focus:ring-2 focus:ring-offset-1 " +
          this.classAttr
        );
      }
    },
  },
};
</script>
