import dayjs from "dayjs";

const getters = {
  loggedIn(state) {
    return state.accessToken != null;
  },
  packageVersion(state) {
    if (state.packageVersion == "20220524.751.46-master") {
      state.packageVersion = "Unpublished";
    }
    return state.packageVersion;
  },
  apiVersion(state) {
    if (state.versionBranch == "LOCAL_DEV") {
      return "Unpublished";
    }
    return state.versionNum + "-" + state.versionBranch;
  },
  accountStatus(state) {
    const daysAmountYellowAlert = 10;
    const daysAmountShowRedAlert = 5;
    if (state.organization_expires === null)
      return {
        alwaysActive: true,
        isExpired: false,
        showYellowWarning: false,
        showRedWarning: false,
      };
    else
      return {
        alwaysActive: false,
        isExpired: dayjs().isAfter(dayjs(state.organization_expires)),
        showYellowWarning: dayjs().add(daysAmountYellowAlert, "day").isAfter(dayjs(state.organization_expires)),
        showRedWarning: dayjs().add(daysAmountShowRedAlert, "day").isAfter(dayjs(state.organization_expires)),
      };
  },
  getCountryDictionary: (state) => (id) => {
    return state.country_codes.length === 0 ? { name: " " } : state.country_codes.find((country) => country.id == id);
  },
  getMyPermission(state) {
    return state.permission_list;
  },
  // return translation key for user organization_country
  getMyOrganizationCountryTK(state) {
    return !state.country_codes.length
      ? " "
      : state.country_codes.find(({ id }) => id == state.organization_country).tk;
  },
};

export default getters;
