<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    ref="wrap"
    class="flow-root max-h-80 overflow-auto"
    @scroll="scroll()">
    <ul ref="list">
      <li
        v-if="allActionables.length == 0"
        class="cursor-pointer px-4">
        <div class="relative flex space-x-3">
          <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
            <div>
              <p class="confirm-delete-text-v2">
                {{ $t("notifications.no_notifications") }}
              </p>
            </div>
          </div>
        </div>
      </li>
      <li
        v-for="note in allActionables"
        :key="note.id"
        class="cursor-pointer px-4 hover:bg-gray-100"
        @click="readNotif(note)">
        <router-link
          :title="$t('notifications.open')"
          :to="'/' + orderURL(note.notification_type) + '/' + note.context.order_pk + '/show'"
          @click.prevent>
          <div class="relative pb-8">
            <div class="relative flex space-x-3">
              <div class="flex items-center">
                <Icon
                  v-if="[1, 4, 7].includes(note.notification_type)"
                  icon-name="notification.new"
                  class-attr="text-green-500 w-10 h-10" />
                <Icon
                  v-if="[2, 5, 8].includes(note.notification_type)"
                  icon-name="notification.in_progress"
                  class-attr="text-yellow-400 w-10 h-10" />
                <Icon
                  v-if="[3, 6, 9].includes(note.notification_type)"
                  icon-name="notification.completed"
                  class-attr="text-green-500 w-10 h-10" />
                <Icon
                  v-if="[0, 10].includes(note.notification_type)"
                  icon-name="notification.admin"
                  class-attr="text-red-600 w-10 h-10" />
                <Icon
                  v-if="[11, 12].includes(note.notification_type)"
                  icon-name="notification.calendar"
                  class-attr="text-red-600 w-10 h-10" />
                <Icon
                  v-if="[100].includes(note.notification_type)"
                  icon-name="notification.update"
                  class-attr="text-indigo-600 w-10 h-10" />
              </div>
              <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                <div>
                  <p class="confirm-delete-text-v2">
                    <span
                      v-if="![10, 0].includes(note.notification_type)"
                      class="text-gray-900 font-medium"
                      >{{ note.context.order_id }}</span
                    >
                    <span
                      v-if="[10, 0].includes(note.notification_type)"
                      class="text-gray-500 font-bold"
                      >{{ $t("notifications.system_message") }}</span
                    >
                  </p>
                  <p
                    v-if="[1, 4, 7].includes(note.notification_type)"
                    class="text-sm text-gray-500 font-bold">
                    {{ $t("notifications.new_order") }}
                  </p>
                  <p
                    v-if="[2, 5, 8].includes(note.notification_type)"
                    class="text-sm text-gray-500 font-bold">
                    {{ $t("notifications.status_change") }}
                    <br />
                    <span class="font-bold">{{ statusName(note.context.new_status) }}</span>
                  </p>
                  <p
                    v-if="[3, 6, 9].includes(note.notification_type)"
                    class="text-sm text-gray-500 font-bold"
                    v-html="$t('notifications.order_completed')" />
                  <p
                    v-if="[0, 10].includes(note.notification_type)"
                    class="text-sm text-gray-500 font-bold">
                    <span
                      class="font-normal"
                      v-html="note.context.data" />
                  </p>
                  <p
                    v-if="[11, 12].includes(note.notification_type)"
                    class="text-sm text-gray-500 font-bold"
                    v-html="$t('notifications.date_estimate_changed')" />
                  <span
                    v-if="[11, 12].includes(note.notification_type)"
                    class="confirm-delete-text-v2"
                    v-html="
                      $t('notifications.date_estimate_changed_msg', [note.context.old_date, note.context.new_date])
                    " />
                  <p
                    v-if="[100].includes(note.notification_type)"
                    class="text-sm text-gray-500 font-bold"
                    v-html="$t('notifications.system_update_scheduled')" />
                  <span
                    v-if="[100].includes(note.notification_type)"
                    class="confirm-delete-text-v2">
                    {{ $t("notifications.system_update_scheduled_to", []) }}<br />
                    <time
                      ><b>{{ updateDate(note.context.update_schedule) }}</b></time
                    >
                    <br />
                    <span v-html="$t('notifications.system_update_extra', [])"></span>
                  </span>
                </div>
                <div class="text-right text-sm whitespace-nowrap text-gray-500">
                  <time>{{ createdDate(note.created) }}</time>
                </div>
              </div>
            </div>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from "moment/min/moment-with-locales";
import { uspAPI } from "../api/axios-base";
import { statusName } from "../modules/status_utils";
import Icon from "./icons/Icon.vue";

export default {
  name: "NotificationFeed",
  components: {
    Icon,
  },
  props: { allActionables: { type: Array, default: new Array() }, nextPage: { type: Number, default: 1 } },
  computed: {},
  mounted() {},
  methods: {
    statusName,
    orderURL(id) {
      switch (id) {
        case 1:
          return "orders";
        case 2:
          return "orders";
        case 3:
          return "orders";
        case 4:
          return "nplateorders";
        case 5:
          return "nplateorders";
        case 6:
          return "nplateorders";
        case 7:
          return "scrap-orders";
        case 8:
          return "scrap-orders";
        case 9:
          return "scrap-orders";
        case 11:
          return "nplateorders";
        case 12:
          return "orders";
        default:
          return "#none";
      }
    },
    orderRoute(id) {
      switch (id) {
        case 1:
          return "OrderShow";
        case 2:
          return "OrderShow";
        case 3:
          return "OrderShow";
        case 4:
          return "NPlateOrderShow";
        case 5:
          return "NPlateOrderShow";
        case 6:
          return "NPlateOrderShow";
        case 7:
          return "ScrapOrderShow";
        case 8:
          return "ScrapOrderShow";
        case 9:
          return "ScrapOrderShow";
        case 11:
          return "NPlateOrderShow";
        case 12:
          return "OrderShow";
        default:
          return "none";
      }
    },
    scroll() {
      let bottomOfWindow = this.$refs.wrap.offsetHeight + this.$refs.wrap.scrollTop === this.$refs.list.offsetHeight;
      if (bottomOfWindow) {
        this.$parent._reloadActionables(this.nextPage);
      }
    },
    readNotif(note) {
      this.$parent.showFeed = false;
      uspAPI
        .post("/api/notifications/mark-as-read/" + note.id + "/", null, {
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
        })
        .then((response) => {
          this.$parent._reloadActionables(1, true);
          this.$parent.checkActionables();
          this.$router.push({ name: this.orderRoute(note.notification_type), params: { id: note.context.order_pk } });
          return response;
        })
        .catch((err) => {
          if (err.response.status == 403) {
            this.$store.dispatch("setConfirmMessage", false);
            this.$router.push({ name: "Error403", params: { id: "NOTIFICATIONS_MARK_AS_READ" } });
          }
        });
    },
    createdDate(date) {
      return moment(date).locale(this.$i18n.locale).fromNow(); //.format('DD MMM')
    },
    updateDate(date) {
      var t_now = moment(new Date());
      var t_then = moment(date);
      if (t_then.diff(t_now, "seconds") > 86400) {
        return moment(date).locale(this.$i18n.locale).format("LLL");
        //.format('DD MMM')
      }
      return (
        moment(date).locale(this.$i18n.locale).fromNow() +
        " (" +
        moment(date).locale(this.$i18n.locale).format("LLL") +
        ")"
      );
      //.format('DD MMM')
    },
  },
};
</script>
