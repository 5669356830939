<template>
  <div class="absolute z-10 inset-0 overflow-y-auto h-screen">
    <div
      class="fixed w-screen h-screen transition-opacity background-filter-blur"
      aria-hidden="true">
      <div class="absolute inset-0 bg-gray-100 opacity-75" />
    </div>
    <div
      class="fixed flex items-center justify-center"
      style="top: 0; left: 50%; bottom: 0">
      <svg
        class="animate-spin -ml-1 mr-3 h-48 w-48 inline-block align-bottom"
        :class="{
          'text-blue-400': $store.state.versionBranch == 'prod',
          'text-green-400': $store.state.versionBranch == 'dev',
          'text-red-600': $store.state.versionBranch == 'master',
          'text-rainbow': $store.state.versionBranch == 'LOCAL',
        }"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4" />
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "Spinner",
  props: {},
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
