import i18n from "../i18n";

const mutations = {
  updateLocalStorage(state, { access, refresh, remember }) {
    var storageMechanism = sessionStorage;
    if (remember == true) {
      storageMechanism = localStorage;
      state.remember = 1;
      storageMechanism.setItem("remember", 1);
    }

    storageMechanism.setItem("access_token", access);
    storageMechanism.setItem("refresh_token", refresh);
    storageMechanism.setItem("authenticated", 1);
    storageMechanism.setItem("lang", state.lang);

    state.accessToken = access;
    state.refreshToken = refresh;
    state.authenticated = 1;
    i18n.global.locale = state.lang;
  },
  updateUserData(state, data) {
    var storageMechanism = sessionStorage;
    if (state.remember == true) {
      storageMechanism = localStorage;
    }

    storageMechanism.setItem("first_name", data.first_name);
    state.first_name = data.first_name;

    storageMechanism.setItem("last_name", data.last_name);
    state.last_name = data.last_name;

    if (data.username) {
      storageMechanism.setItem("username", data.username);
      state.username = data.username;
    }

    if (typeof data.id !== "undefined") {
      storageMechanism.setItem("user_id", data.id);
      state.user_id = data.id;
    }

    if (typeof data.home_org !== "undefined") {
      if (typeof data.home_org.country !== "undefined") {
        storageMechanism.setItem("organization_country", data.home_org.country);
        state.organization_country = data.home_org.country;
      }
      if (data.home_org.expires) {
        storageMechanism.setItem("organization_expires", data.home_org.expires);
        state.organization_expires = data.home_org.expires;
      } else {
        storageMechanism.setItem("organization_expires", "null");
        state.organization_expires = null;
      }
      if (typeof data.home_org.district !== "undefined") {
        storageMechanism.setItem("organization_district", data.home_org.district);
        state.organization_district = data.home_org.district;
      }
      if (data.home_org.id) {
        storageMechanism.setItem("organization_id", data.home_org.id);
        state.organization_id = data.home_org.id;
      }
      if (data.home_org.default_raw_plate_supplier) {
        storageMechanism.setItem("default_raw_supplier_id", data.home_org.default_raw_plate_supplier);
        state.default_raw_supplier_id = data.home_org.default_raw_plate_supplier;
      }

      if (typeof data.home_org.name !== "undefined") {
        storageMechanism.setItem("organization_name", data.home_org.name);
        state.organization_name = data.home_org.name;
      }

      if (typeof data.home_org.is_complete_plate_manufacturer !== "undefined") {
        storageMechanism.setItem("is_complete_plate_manufacturer", data.home_org.is_complete_plate_manufacturer);
        state.is_complete_plate_manufacturer = data.home_org.is_complete_plate_manufacturer;
      }

      if (typeof data.home_org.is_raw_plate_manufacturer !== "undefined") {
        storageMechanism.setItem("is_raw_plate_manufacturer", data.home_org.is_raw_plate_manufacturer);
        state.is_raw_plate_manufacturer = data.home_org.is_raw_plate_manufacturer;
      }

      if (typeof data.home_org.is_issuing_authority !== "undefined") {
        storageMechanism.setItem("is_issuing_authority", data.home_org.is_issuing_authority);
        state.is_issuing_authority = data.home_org.is_issuing_authority;
      }

      if (typeof data.home_org.requires_contracts !== "undefined") {
        storageMechanism.setItem("is_requires_contracts", data.home_org.requires_contracts);
        state.is_requires_contracts = data.home_org.requires_contracts;
      }

      if (typeof data.home_org.invoice_info !== "undefined") {
        storageMechanism.setItem("invoice_info", data.home_org.invoice_info);
        state.invoice_info = data.home_org.invoice_info;
      }
    }
    if (typeof data.is_staff !== "undefined") {
      storageMechanism.setItem("is_staff", data.is_staff);
      state.is_staff = data.is_staff;
    }
  },
  updateAccessToken(state, access) {
    state.accessToken = access;
    var storageMechanism = sessionStorage;
    if (state.remember == true) {
      storageMechanism = localStorage;
    }
    storageMechanism.setItem("access_token", access);
  },
  updateRefreshToken(state, refresh) {
    state.refreshToken = refresh;
    var storageMechanism = sessionStorage;
    if (state.remember == true) {
      storageMechanism = localStorage;
    }
    storageMechanism.setItem("refresh_token", refresh);
  },
  destroyToken(state) {
    state.accessToken = null;
    state.refreshToken = null;
    state.authenticated = 0;
    state.permission_list = [];
    state.is_staff = false;
    state.invoice_info = null;
    state.organization_expires = null;
    state.organization_district = null;
    state.organization_name = null;
    state.organization_id = null;
    state.default_raw_supplier_id = null;
    state.user_id = null;
    state.username = null;
    state.first_name = null;
    state.last_name = null;
  },
  mutateAppVer(state, data) {
    state.versionNum = data.version;
    state.versionBranch = data.branch;
    state.versionDate = data.date;
  },
  updatePermissionList(state, permission_list) {
    var storageMechanism = sessionStorage;
    if (state.remember == true) {
      storageMechanism = localStorage;
    }
    storageMechanism.setItem("permission_list", JSON.stringify(permission_list));

    state.permission_list = permission_list;
  },
  updateCountryList(state, country_codes) {
    var storageMechanism = localStorage;
    storageMechanism.setItem("country_codes", JSON.stringify(country_codes));
    state.country_codes = country_codes;
  },
  updateManufacturerList(state, manufacturer_codes) {
    var storageMechanism = localStorage;
    storageMechanism.setItem("manufacturer_codes", JSON.stringify(manufacturer_codes));
    state.manufacturer_codes = manufacturer_codes;
  },
  updateFilterNplate(state, data) {
    state.filterNplate = data;
  },
  updateFilterOrder(state, data) {
    state.filterOrder = data;
  },
  updateFilterRaw(state, data) {
    state.filterRaw = data;
  },
  updateFilterScrap(state, data) {
    state.filterScrap = data;
  },
  updateConfirmMessage(state, data) {
    state.showConfirmMessage = data;
  },
  updateLoading(state, data) {
    state.loadingSpinner = data;
  },
  updateSidebar(state, data) {
    state.openSidebar = data;
  },
};

export default mutations;
