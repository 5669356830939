function removeStorages(key) {
  sessionStorage.removeItem(key);
  localStorage.removeItem(key);
}
function removeAllStored() {
  removeStorages("access_token");
  removeStorages("refresh_token");
  removeStorages("authenticated");
  removeStorages("remember");
  removeStorages("first_name");
  removeStorages("last_name");
  removeStorages("username");
  removeStorages("user_id");
  removeStorages("is_complete_plate_manufacturer");
  removeStorages("is_raw_plate_manufacturer");
  removeStorages("is_issuing_authority");
  removeStorages("is_requires_contracts");
  removeStorages("invoice_info");
  removeStorages("organization_id");
  removeStorages("organization_name");
  removeStorages("organization_country");
  removeStorages("organization_district");
  removeStorages("organization_expires");
  removeStorages("permission_list");
  removeStorages("is_staff");
  removeStorages("lang");
  removeStorages("SEARCH_LAST_INDICATOR");
  removeStorages("versionNum");
  removeStorages("versionBranch");
  removeStorages("versionDate");
}
function removeCountries() {
  removeStorages("country_codes");
}
export { removeStorages, removeAllStored, removeCountries };
export default removeStorages;
