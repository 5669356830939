import { removeStorages, removeAllStored, removeCountries } from "../helpers/storeHelpers";
import { axiosBase, uspAPI } from "../api/axios-base";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const actions = {
  // run the below action to get a new access token on expiration
  setAppVer(state, data) {
    state.versionNum = data.version;
    state.versionBranch = data.branch;
    state.versionDate = data.date;
    removeStorages("versionNum");
    removeStorages("versionBranch");
    removeStorages("versionDate");
    var storageMechanism = localStorage;
    storageMechanism.setItem("versionNum", state.versionNum);
    storageMechanism.setItem("versionBranch", state.versionBranch);
    storageMechanism.setItem("versionDate", state.versionDate);
    state.commit("mutateAppVer", data);
  },
  async refreshTokens(context) {
    if (!context.state.mutexTokenRefresh) {
      context.state.mutexTokenRefresh = true;
      return new Promise((resolve, reject) => {
        axiosBase
          .post("/api/user/token/refresh/", {
            refresh: context.state.refreshToken,
          }) // send the stored refresh token to the backend API
          .then((response) => {
            // if API sends back new access and refresh token update the store
            context.commit("updateAccessToken", response.data.access);
            context.commit("updateRefreshToken", response.data.refresh);
            context.state.mutexTokenRefresh = false;
            resolve(response.data.access);
          })
          .catch((err) => {
            // this makes more sense, as it cleans out all the things instead of only token
            removeAllStored();
            context.commit("destroyToken");
            removeCountries();
            window.location.reload();
            reject(err); // error generating new access and refresh token because refresh token has expired
          });
      });
    } else {
      // DRAGONS: This is hacky as hell. The gist of it is this: we need to intercept 401 because of token in axios-base (it's handled there) and then
      // DRAGONS: 1. send a refresh token request
      // DRAGONS: 2. wait for it to complete and reissue old request
      // DRAGONS: It works okay if there's only one request intercepted this way in a short period (before refresh completes)
      // DRAGONS: With more requests (e.g. notifications) it breaks, causing additional requests to refresh to fail after security upgrade on 30/07/2023
      // DRAGONS: That causes a logout. This here fixes that by introducing a mutex and delaying additional requests by half-a-second
      return new Promise((resolve, reject) => {
        axiosBase
          .get("/api/version/", {})
          .then(async () => {
            await delay(500 + Math.random() * 2000.0);
            resolve(context.state.accessToken);
          })
          .catch((err) => {
            reject(err);
          });
      });
    }
  },
  registerUser(context, data) {
    return new Promise((resolve, reject) => {
      axiosBase
        .post("/api/user/sign_up/", {
          username: data.username,
          password1: data.password1,
          password2: data.password2,
          first_name: data.first_name,
          last_name: data.last_name,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  logoutUser(context) {
    if (context.getters.loggedIn) {
      removeAllStored();
      context.commit("destroyToken");
      removeCountries();
    }
  },
  loginUser(context, credentials) {
    return new Promise((resolve, reject) => {
      // send the username and password to the backend API:
      axiosBase
        .post("/api/user/log_in/", {
          username: credentials.username,
          password: credentials.password,
        })
        // if successful update local storage:
        .then((response) => {
          context.commit("updateLocalStorage", {
            access: response.data.access,
            refresh: response.data.refresh,
            remember: credentials.remember,
          }); // store the access and refresh token in localstorage
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  updateUser(context, data) {
    return new Promise((resolve) => {
      context.commit("updateUserData", data);
      resolve();
    });
  },

  checkPermissions(identifier, user, context, assume = true) {
    return new Promise((resolve, reject) => {
      // send the data and check permission:
      axiosBase
        .post("/api/organizations/permission-check/", {
          identifier: identifier,
          user: user,
          context: context,
          assume: assume,
        })
        // if successful update local storage:
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  checkCountryCodes({ dispatch, state }) {
    if (!state.country_codes.length) dispatch("downloadCountryCodes");
  },
  async downloadCountryCodes({ commit, state }) {
    const maxAmountRequest = 50;
    let countryCodes = [];
    let page = 1;
    do {
      await uspAPI
        .get(`/api/orders/country/?page=${page}`, { headers: { Authorization: `Bearer ${state.accessToken}` } })
        .then(({ data }) => {
          const countryPack = data.results.map((country) => {
            return {
              id: country.id,
              name: country.name,
              code3: country.iso_a3_code,
              tk: "countries." + country.iso_a3_code,
            };
          });
          countryCodes = [...countryCodes, ...countryPack];
          if (data.next === null) page = maxAmountRequest;
        })
        .catch(() => {
          // NOHANDLER: This has ALLOW_ALL permission
          page = maxAmountRequest;
        });
      page++;
    } while (page < maxAmountRequest);
    commit("updateCountryList", countryCodes);
  },
  // always update manufactures codes when component needs
  checkManufacturerCodes({ dispatch }) {
    dispatch("downloadManufactures");
  },
  async downloadManufactures({ commit, state }) {
    const maxAmountRequest = 50;
    let manufacturesCodes = [];
    let page = 1;
    do {
      await uspAPI
        .get(`/api/user/manufacturers/?page=${page}`, { headers: { Authorization: `Bearer ${state.accessToken}` } })
        .then(({ data }) => {
          const manPack = data.results.map(({ id, short_name, name }) => {
            return { id, short_name, name };
          });
          manufacturesCodes = [...manufacturesCodes, ...manPack];
          if (data.next === null) page = maxAmountRequest;
        })
        .catch(() => {
          page = maxAmountRequest;
        });
      page++;
    } while (page < maxAmountRequest);
    commit("updateManufacturerList", manufacturesCodes);
  },
  setFilterNplate({ commit }, data) {
    commit("updateFilterNplate", data);
  },
  setFilterOrder({ commit }, data) {
    commit("updateFilterOrder", data);
  },
  setFilterRaw({ commit }, data) {
    commit("updateFilterRaw", data);
  },
  setFilterScrap({ commit }, data) {
    commit("updateFilterScrap", data);
  },
  setConfirmMessage({ commit }, data) {
    commit("updateConfirmMessage", data);
  },
  loadingStart({ commit }) {
    commit("updateLoading", true);
  },
  loadingEnd({ commit }) {
    commit("updateLoading", false);
  },
  changeSideBar({ commit }, data) {
    commit("updateSidebar", data);
  },
};

export default actions;
