const closeCardAction = (e) => {
  e.preventDefault();
  e.returnValue = "1";
};

export default {
  created() {
    window.addEventListener("beforeunload", closeCardAction);
  },
  unmounted() {
    window.removeEventListener("beforeunload", closeCardAction);
  },
};
