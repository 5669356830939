import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
import store from "../store";
import routes from "./router";

const APIUrl = process.env.VUE_APP_API_URL;

const uspAPI = axios.create({
  baseURL: APIUrl,
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  // after moved loadingSpinner to store value. Change page turn off loading
  //  TODO better idea add meta prop to start page with loading on or not
  store.dispatch("loadingEnd");
  uspAPI
    .get("/api/version/")
    .then((response) => {
      if (!store.state.versionBranch || store.state.versionDate != response.data.date) {
        store.dispatch("setAppVer", response.data);
      }
    })
    .catch(); // NOHANDLER: This has ALLOW_ALL permission
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (store.state.authenticated != 1) {
      next({
        path: "/login",
      });
    } else {
      next();
    }
  } else {
    next(); // make sure to always call next()!
  }
});
export default router;
