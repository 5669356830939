import PassThrough from "../views/PassThrough.vue";
import Root from "../views/Root.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Root,
    beforeEnter: (to, from, next) => {
      if (store.state.authenticated != 0) {
        if (store.getters.accountStatus.isExpired) {
          next({
            name: "expiredPage",
          });
        }
        next();
      } else {
        next({
          name: "Login",
        });
      }
    },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "Base",
        component: PassThrough,
        beforeEnter: (to, from, next) => {
          if (
            !store.state.is_complete_plate_manufacturer &&
            !store.state.is_raw_plate_manufacturer &&
            !store.state.is_issuing_authority
          ) {
            next({
              name: "OrganizationProfile",
            });
          } else if (!store.state.is_complete_plate_manufacturer && !store.state.is_issuing_authority) {
            next({
              name: "OrderList",
            });
          } else {
            next({
              name: "NPlateOrderList",
            });
          }
        },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "error403/:id",
        name: "Error403",
        component: () => import("../views/Error403.vue"),
        beforeEnter: (to, from, next) => {
          next();
        },
        meta: {
          requiresAuth: false,
        },
      },
      {
        path: "internal-orders",
        name: "InternalOrders",
        component: PassThrough,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "",
            name: "InternalOrderList",
            component: () => import("../views/InternalOrders.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_raw_plate_manufacturer) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "internal-order-create",
            name: "InternalOrderCreate",
            component: () => import("../views/InternalOrderCreate.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_raw_plate_manufacturer) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/internal-order-show",
            name: "InternalOrderShow",
            component: () => import("../views/InternalOrderShow.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_raw_plate_manufacturer) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/internal-order-edit",
            name: "InternalOrderEdit",
            component: () => import("../views/InternalOrderEdit.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_raw_plate_manufacturer) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/internal-order-verify",
            name: "InternalOrderVerify",
            component: () => import("../views/InternalOrderVerify.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_raw_plate_manufacturer) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "orders",
        name: "Orders",
        component: PassThrough,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "",
            name: "OrderList",
            component: () => import("../views/Orders.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_raw_plate_manufacturer && !store.state.is_complete_plate_manufacturer) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/verify",
            name: "OrderVerify",
            component: () => import("../views/OrderVerify.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_raw_plate_manufacturer && !store.state.is_complete_plate_manufacturer) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/edit",
            name: "OrderEdit",
            component: () => import("../views/OrderEdit.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_raw_plate_manufacturer && !store.state.is_complete_plate_manufacturer) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/show",
            name: "OrderShow",
            component: () => import("../views/OrderShow.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_raw_plate_manufacturer && !store.state.is_complete_plate_manufacturer) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "create",
            name: "OrderCreate",
            component: () => import("../views/OrderCreate.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_raw_plate_manufacturer && !store.state.is_complete_plate_manufacturer) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "nplateorders",
        name: "NPlateOrders",
        component: PassThrough,
        beforeEnter: (to, from, next) => {
          if (!store.state.is_complete_plate_manufacturer && !store.state.is_issuing_authority) {
            next({
              name: "Base",
            });
          } else {
            next();
          }
        },
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "",
            name: "NPlateOrderList",
            component: () => import("../views/NPlateOrders.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_complete_plate_manufacturer && !store.state.is_issuing_authority) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "create",
            name: "NPlateOrderCreate",
            component: () => import("../views/NPlateOrderCreate.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_issuing_authority) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/show",
            name: "NPlateOrderShow",
            component: () => import("../views/NPlateOrderShow.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_complete_plate_manufacturer && !store.state.is_issuing_authority) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/verify",
            name: "NPlateOrderVerify",
            component: () => import("../views/NPlateOrderVerify.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_complete_plate_manufacturer && !store.state.is_issuing_authority) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/edit",
            name: "NPlateOrderEdit",
            component: () => import("../views/NPlateOrderEdit.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_issuing_authority) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "organization",
        name: "Organization",
        component: PassThrough,
        children: [
          {
            path: "organization",
            name: "OrganizationProfile",
            component: () => import("../views/Organization.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "organization-edit/:id",
            name: "OrganizationEdit",
            component: () => import("../views/OrganizationEdit.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "organization-list",
            name: "OrganizationList",
            component: () => import("../views/OrganizationList.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "delivery-addresses",
            name: "DeliveryAddresses",
            component: PassThrough,
            children: [
              {
                path: "",
                name: "List delivery addresses",
                component: () => import("../views/OrganizationDeliveryAddresses.vue"),
                meta: {
                  requiresAuth: true,
                },
              },
              {
                path: "create",
                name: "Create delivery info",
                component: () => import("../views/OrganizationDeliveryAddressCreate.vue"),
                meta: {
                  requiresAuth: true,
                },
              },
              {
                path: ":id/edit",
                name: "Edit delivery info",
                component: () => import("../views/OrganizationDeliveryAddressEdit.vue"),
                meta: {
                  requiresAuth: true,
                },
              },
            ],
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "users-data",
            name: "UsersData",
            component: () => import("../views/OrganizationUsers.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "user-create",
            name: "UserCreate",
            component: () => import("../views/OrganizationUserCreate.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/user-edit",
            name: "UserEdit",
            component: () => import("../views/OrganizationUserEdit.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/user-info",
            name: "UserInfo",
            component: () => import("../views/OrganizationUserInfo.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/user-permissions",
            name: "User permissions",
            component: () => import("../views/OrganizationUserPermissions.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/add-user-permissions",
            name: "Add User permissions",
            component: () => import("../views/OrganizationAddUserPermissions.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "users-foreign",
            name: "Users foreign members",
            component: () => import("../views/OrganizationUsersForeign.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/user-foreign-permissions",
            name: "Users foreign members permissions",
            component: () => import("../views/OrganizationUserForeignPermissions.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/add-user-foreign-permissions",
            name: "Add users foreign members permissions",
            component: () => import("../views/OrganizationAddUserForeignPermissions.vue"),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "add-organization",
            name: "Add organization",
            component: () => import("../views/OrganizationCreate.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_staff) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "profile",
        name: "Profile",
        component: () => import("../views/Profile.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "scrap-orders",
        name: "ScrapOrders",
        component: PassThrough,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "",
            name: "ScrapOrderList",
            component: () => import("../views/ScrapOrders.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_complete_plate_manufacturer && !store.state.is_issuing_authority) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "create",
            name: "Add new scrap order",
            component: () => import("../views/ScrapOrderCreate.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_issuing_authority) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/show",
            name: "ScrapOrderShow",
            component: () => import("../views/ScrapOrderShow.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_complete_plate_manufacturer && !store.state.is_issuing_authority) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/verify",
            name: "ScrapOrderVerify",
            component: () => import("../views/ScrapOrderVerify.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_complete_plate_manufacturer) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/edit",
            name: "ScrapOrderEdit",
            component: () => import("../views/ScrapOrderEdit.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_issuing_authority) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "used-number-ranges",
        name: "Used number ranges",
        component: PassThrough,
        children: [
          {
            path: "",
            name: "Used number ranges list",
            component: () => import("../views/UsedNumberRanges.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_issuing_authority) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "create",
            name: "Add new used number range",
            component: () => import("../views/UsedNumberRangeCreate.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_issuing_authority) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "reserved-number-ranges",
        name: "Reserved number ranges",
        component: PassThrough,
        children: [
          {
            path: "",
            name: "Reserved number ranges list",
            component: () => import("../views/ReservedNumbers.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_issuing_authority) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "create",
            name: "Add new reserved number range",
            component: () => import("../views/ReservedNumberCreate.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_issuing_authority) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/edit",
            name: "Edit reserved number range",
            component: () => import("../views/ReservedNumberEdit.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_issuing_authority) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "contracts",
        name: "Contracts",
        component: PassThrough,
        children: [
          {
            path: "",
            name: "Contracts list",
            component: () => import("../views/Contracts.vue"),
            beforeEnter: (to, from, next) => {
              if (
                !store.state.is_complete_plate_manufacturer &&
                !store.state.is_issuing_authority &&
                !store.state.is_staff
              ) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "create",
            name: "Add new contract",
            component: () => import("../views/ContractCreate.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_issuing_authority) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/edit",
            name: "ContractEdit",
            component: () => import("../views/ContractEdit.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_issuing_authority) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/show",
            name: "ContractInfo",
            component: () => import("../views/ContractInfo.vue"),
            beforeEnter: (to, from, next) => {
              if (
                !store.state.is_complete_plate_manufacturer &&
                !store.state.is_issuing_authority &&
                !store.state.is_staff
              ) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "stock",
        name: "Stock management",
        component: PassThrough,
        children: [
          {
            path: "",
            name: "Stock list",
            component: () => import("../views/Stock.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_complete_plate_manufacturer && !store.state.is_raw_plate_manufacturer) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/details",
            name: "Stock details",
            component: () => import("../views/StockDetails.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_complete_plate_manufacturer && !store.state.is_raw_plate_manufacturer) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "material-stock",
        name: "Material stock management",
        component: PassThrough,
        children: [
          {
            path: "",
            name: "Material stock list",
            component: () => import("../views/MaterialStock.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_raw_plate_manufacturer) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id/details",
            name: "Material details",
            component: () => import("../views/MaterialDetails.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_raw_plate_manufacturer) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "search",
        name: "Number ranges search",
        component: () => import("../views/Search.vue"),
        beforeEnter: (to, from, next) => {
          if (
            !store.state.is_complete_plate_manufacturer &&
            !store.state.is_issuing_authority &&
            !store.state.is_staff
          ) {
            next({
              name: "Base",
            });
          } else {
            next();
          }
        },
      },
      {
        path: "reports",
        name: "Reports",
        component: PassThrough,
        children: [
          {
            path: "production-report",
            name: "Create production report",
            component: () => import("../views/ProductionReport.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_complete_plate_manufacturer && !store.state.is_raw_plate_manufacturer) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "registration-plates-report",
            name: "Create registration plates report",
            component: () => import("../views/RegistrationPlatesReport.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_complete_plate_manufacturer && !store.state.is_issuing_authority) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "production-plates-report",
            name: "Create Production plates report",
            component: () => import("../views/ProductionPlatesReport.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_complete_plate_manufacturer) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "individual-license-report",
            name: "Create individual license report",
            component: () => import("../views/IndividualLicenseReport.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_complete_plate_manufacturer && !store.state.is_issuing_authority) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "stock-history/:itemid/prepare",
            name: "Create raw plate stock history report",
            component: () => import("../views/StockHistoryReport.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_complete_plate_manufacturer) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: "material-stock-history/:itemid/prepare",
            name: "Create material stock history report",
            component: () => import("../views/MaterialStockHistoryReport.vue"),
            beforeEnter: (to, from, next) => {
              if (!store.state.is_complete_plate_manufacturer) {
                next({
                  name: "Base",
                });
              } else {
                next();
              }
            },
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
      {
        path: "acquisitions/:id/show",
        name: "AcquisitionsShow",
        component: () => import("../views/Acquisitions.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.state.is_raw_plate_manufacturer) {
            next({
              name: "Base",
            });
          } else {
            next();
          }
        },
      },
      {
        path: "changelog",
        name: "Changelog",
        component: () => import("../views/Changelog.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.state.authenticated) {
            next({
              name: "Base",
            });
          } else {
            next();
          }
        },
      },
      {
        path: "license",
        name: "License",
        component: () => import("../views/License.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.state.authenticated) {
            next({
              name: "Base",
            });
          } else {
            next();
          }
        },
      },
      {
        path: "terms",
        name: "Terms",
        component: () => import("../views/Terms.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.state.authenticated) {
            next({
              name: "Base",
            });
          } else {
            next();
          }
        },
      },
      {
        path: "privacy",
        name: "PrivacyPolicy",
        component: () => import("../views/PrivacyPolicy.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.state.authenticated) {
            next({
              name: "Base",
            });
          } else {
            next();
          }
        },
      },
      {
        path: "acquisitions",
        name: "Acquisitions",
        component: () => import("../views/Acquisitions.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.state.is_raw_plate_manufacturer) {
            next({
              name: "Base",
            });
          } else {
            next();
          }
        },
      },
      {
        path: "stock-incoming",
        name: "Stock Incoming",
        component: () => import("../views/StockIncoming.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.state.is_raw_plate_manufacturer && !store.state.is_complete_plate_manufacturer) {
            next({
              name: "Base",
            });
          } else {
            next();
          }
        },
      },
      {
        path: "stock-incoming/:id/show",
        name: "StockIncomingShow",
        component: () => import("../views/StockIncoming.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.state.is_raw_plate_manufacturer && !store.state.is_complete_plate_manufacturer) {
            next({
              name: "Base",
            });
          } else {
            next();
          }
        },
      },
      {
        path: "stock-outgoing",
        name: "Stock Outgoing",
        component: () => import("../views/StockOutgoing.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.state.is_raw_plate_manufacturer && !store.state.is_complete_plate_manufacturer) {
            next({
              name: "Base",
            });
          } else {
            next();
          }
        },
      },
      {
        path: "stock-outgoing/:id/show",
        name: "StockOutgoingShow",
        component: () => import("../views/StockOutgoing.vue"),
        beforeEnter: (to, from, next) => {
          if (!store.state.is_raw_plate_manufacturer && !store.state.is_complete_plate_manufacturer) {
            next({
              name: "Base",
            });
          } else {
            next();
          }
        },
      },
      {
        path: "/issue-comment",
        name: "IssueComment",
        component: PassThrough,
        children: [
          {
            path: "list",
            name: "IssueCommentList",
            component: () => import("../views/IssueComment/IssueCommentList.vue"),
          },
          {
            path: "create",
            name: "IssueCommentCreate",
            component: () => import("../views/IssueComment/IssueCommentCreate.vue"),
          },
          {
            path: "edit/:id",
            name: "IssueCommentEdit",
            component: () => import("../views/IssueComment/IssueCommentEdit.vue"),
          },
          {
            path: "show/:id",
            name: "IssueCommentShow",
            component: () => import("../views/IssueComment/IssueCommentShow.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Home.vue"),
    beforeEnter: (to, from, next) => {
      if (store.state.authenticated == 1) {
        next({
          name: "Base",
        });
      } else {
        next();
      }
    },
  },
  {
    path: "/reset",
    name: "ResetConfirm",
    component: () => import("../views/Home.vue"),
    beforeEnter: (to, from, next) => {
      if (store.state.authenticated == 1) {
        next({
          name: "Base",
        });
      } else {
        next();
      }
    },
  },
  {
    path: "/expired",
    name: "Expired",
    component: Root,
    children: [
      {
        path: "",
        name: "expiredPage",
        component: () => import("../views/Expired/Expired.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/redirect",
    name: "Redirect",
    component: Root,
    children: [
      {
        path: "",
        name: "redirectPage",
        component: () => import("../views/Redirect/RedirectPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
];

export default routes;
