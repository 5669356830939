<template>
  <button
    class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:bg-gray-100 focus:text-gray-600 lg:hidden"
    aria-label="Open sidebar"
    @click="$store.state.openSidebar = true">
    <Icon
      icon-name="menu.toggle_menu"
      class-attr="h-6 w-6" />
  </button>
  <div class="flex-1 pr-4 flex">
    <AccountStatusBarInformation />
    <div class="ml-auto mr-0 min-w-fit flex items-center">
      <p class="mr-2 hidden sm:inline-block">
        {{ user }}
      </p>
      <div class="flex items-center">
        <div
          v-if="showAction"
          v-click-outside="onClickOutsideActionables"
          class="relative">
          <button
            class="p-1 rounded-full hover:bg-red-100 hover:text-red-700 focus:outline-none focus:shadow-outline focus:text-red-500"
            aria-label="Notifications"
            :class="{ 'text-red-600': !actionBlink, 'text-orange-400': actionBlink }"
            @click="toggleActionables()">
            <svg
              class="h-8 w-8"
              fill="currentColor"
              viewBox="0 0 24 24">
              <path
                fill-rule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                clip-rule="evenodd" />
            </svg>
          </button>
          <!-- #WARN: This is not a standard button, so for now it stays as is -->
          <div
            class="absolute z-20 top-0 right-0 w-120 bg-white py-3 box-content shadow-md mt-12"
            :class="{
              'transition ease-out duration-100 transform opacity-0 scale-95 invisible': !showActionables,
              'transition ease-in duration-75 transform opacity-100 scale-100 visible': showActionables,
            }">
            <ActionablesFeed
              :all-actionables="allActionables"
              :next-page="pageActionables" />
          </div>
        </div>

        <div
          v-click-outside="onClickOutsideFeed"
          class="relative">
          <span
            v-if="showNotif"
            class="absolute top-0 left-0 w-2 h-2 bg-red-600 rounded-full" />
          <button
            class="p-1 text-gray-400 rounded-full hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:shadow-outline focus:text-gray-500"
            aria-label="Notifications"
            @click="toggleFeed()">
            <Icon
              class-attr="h-6 w-6"
              icon-name="menu.toggle_notifications" />
          </button>
          <!-- #WARN: This is not a standard button, so for now it stays as is -->
          <div
            class="absolute z-20 top-0 right-0 w-120 bg-white py-3 box-content shadow-md mt-12"
            :class="{
              'transition ease-out duration-100 transform opacity-0 scale-95 invisible': !showFeed,
              'transition ease-in duration-75 transform opacity-100 scale-100 visible': showFeed,
            }">
            <NotificationFeed
              :all-notifications="allNotifications"
              :next-page="pageNotifcations" />
          </div>
        </div>

        <!-- Profile dropdown -->
        <span v-click-outside="onClickOutsideProfile">
          <div class="ml-3 relative">
            <div>
              <button
                id="user-menu"
                class="w-8 h-8 flex items-center text-sm rounded-full focus:outline-none focus:shadow-outline"
                aria-label="User menu"
                aria-haspopup="true"
                @click="toggleProfile()">
                <img
                  class="h-8 w-8 rounded-full"
                  src="/profile_head.png"
                  alt="" />
              </button>
            </div>
            <div
              class="block origin-top-right absolute z-20 right-0 mt-2 w-48 rounded-md shadow-lg"
              :class="{
                'transition ease-out duration-100 transform opacity-0 scale-95 invisible': !showProfile,
                'transition ease-in duration-75 transform opacity-100 scale-100 visible': showProfile,
              }">
              <div
                class="py-1 rounded-md bg-white shadow-xs"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu">
                <router-link
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150"
                  to="/profile">
                  {{ $t("menu.profile") }}
                </router-link>
                <a
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150"
                  @click.middle="$store.state.premium = !$store.state.premium"
                  @click.prevent="showLang = !showLang">
                  {{ $t("actions.change_language") }}
                </a>
                <div
                  v-if="showLang"
                  v-click-outside="onClickOutsideLang"
                  class="block origin-top-right absolute z-20 right-48 mr-1 mt-2 top-4 w-48 rounded-md py-1 bg-white shadow-xs"
                  :class="{
                    'transition ease-out duration-100 transform opacity-0 scale-95 invisible': !showProfile,
                    'transition ease-in duration-75 transform opacity-100 scale-100 visible': showProfile,
                  }">
                  <a
                    v-for="lang in $i18n.availableLocales"
                    :key="lang"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150"
                    @click.prevent="
                      localeTo = lang;
                      updateLocale();
                    ">
                    {{ $i18n.messages[lang]["_LANG_NAME_"] }}
                  </a>
                </div>
                <router-link
                  class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 transition ease-in-out duration-150"
                  to="/"
                  @click.prevent="$root.logout()">
                  {{ $t("actions.logout") }}
                </router-link>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationFeed from "@/components/NotificationFeed.vue";
import AccountStatusBarInformation from "@/components/AccountStatusBarInformation.vue";
import ActionablesFeed from "@/components/ActionablesFeed.vue";
import vClickOutside from "click-outside-vue3";
import { uspAPI } from "../api/axios-base";
import EventBus from "../api/eventBus";
import i18n from "../i18n";
import Icon from "./icons/Icon.vue";
export default {
  name: "UserBar",
  components: {
    NotificationFeed,
    ActionablesFeed,
    Icon,
    AccountStatusBarInformation,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      showProfile: false,
      showActionables: false,
      showAction: false,
      showFeed: false,
      showNotif: false,
      showLang: false,
      user: "",
      notifReloadProp: false,
      actionReloadProp: false,
      localeTo: "",
      actionBlink: false,
      // notification interval time in second
      // LOAD-BEARING-CODE: Do not remove line below. Seriously. It may appear to mean exactly the same as just stating 60, but imagine you have to set this interval to say... 90 on prod but need it to be 10 on testing, because you want notifications to not kill prod server but have a bug you need to fix and don't wish to spend whole day on testing this
      reactionForReloadTime: process.env.VUE_APP_FAVICON === "favicon-local.ico" ? 60 : 60,
      allNotifications: [],
      allActionables: [],
      pageNotifcations: 1,
      pageActionables: 1,
    };
  },
  mounted() {
    // this.checkNotification()
    this.user =
      this.$store.state.first_name +
      " " +
      this.$store.state.last_name +
      " (" +
      this.$store.state.organization_name +
      ")";
    EventBus.$on("callUpdateUserBar", () => {
      this.updateUsername();
    });
    window.setTimeout(this.checkAllNotifs, 1000 * this.reactionForReloadTime);
    window.setTimeout(this.blinkerAction, 650);
  },
  methods: {
    checkAllNotifs() {
      this.checkActionables();
      window.setTimeout(this.checkAllNotifs, 1000 * this.reactionForReloadTime);
    },
    blinkerAction() {
      this.actionBlink = !this.actionBlink;
      window.setTimeout(this.blinkerAction, 650);
    },
    updateLocale() {
      let availableLocales = this.$i18n.availableLocales;
      let find_locale = availableLocales.indexOf(this.localeTo);
      if (find_locale > -1) {
        this.$store.state.lang = this.localeTo;
        i18n.global.locale = this.$store.state.lang;
      } else {
        this.$store.state.lang = "pl-PL";
        i18n.global.locale = this.$store.state.lang;
      }
      const html = document.documentElement; // returns the html tag
      html.setAttribute("lang", this.$store.state.lang);
    },
    updateUsername() {
      this.user =
        this.$store.state.first_name +
        " " +
        this.$store.state.last_name +
        " (" +
        this.$store.state.organization_name +
        ")";
    },
    onClickOutsideFeed() {
      let _this = this;
      setTimeout(function () {
        if (_this.showFeed) _this.showFeed = false;
      }, 300);
    },
    onClickOutsideActionables() {
      let _this = this;
      setTimeout(function () {
        if (_this.showActionables) _this.showActionables = false;
      }, 300);
    },
    onClickOutsideProfile() {
      let _this = this;
      setTimeout(function () {
        if (_this.showProfile) _this.showProfile = false;
        _this.showLang = false;
      }, 300);
    },
    onClickOutsideLang() {
      let _this = this;
      setTimeout(function () {
        if (_this.showLang) _this.showLang = false;
      }, 300);
    },
    checkNotification() {
      if (this.$store.state.authenticated) {
        uspAPI
          .get("/api/notifications/unread-count/", {
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then((response) => {
            if (response.data.count > 0) {
              this.showNotif = true;
            } else {
              this.showNotif = false;
            }
          })
          .catch((err) => {
            if (err.response && err.response.status == 403) {
              this.$store.dispatch("setConfirmMessage", false);
              this.$router.push({ name: "Error403", params: { id: "NOTIFICATIONS_UNREAD_COUNTER" } });
            }
          });
        this.notifReloadProp = !this.notifReloadProp;
        // window.setTimeout(this.checkNotification, 1000 * this.reactionForReloadTime);
      }
    },
    checkActionables() {
      if (this.$store.state.authenticated) {
        uspAPI
          .get("/api/notifications/prominent-count/", {
            headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
          })
          .then((response) => {
            if (response.data.count > 0) {
              this.showAction = true;
            } else {
              this.showAction = false;
            }
            this.checkNotification();
          })
          .catch((err) => {
            console.log(err);
            if (err.response.status == 403) {
              this.$store.dispatch("setConfirmMessage", false);
              this.$router.push({ name: "Error403", params: { id: "NOTIFICATIONS_UNREAD_COUNTER" } });
            }
          });
        this.actionReloadProp = !this.actionReloadProp;
        // window.setTimeout(this.checkActionables, 1000 * this.reactionForReloadTime);
      }
    },
    toggleProfile() {
      this.showFeed = false;
      this.showActionables = false;
      this.showProfile = !this.showProfile;
    },
    toggleActionables() {
      this.showProfile = false;
      this.showFeed = false;
      this.showActionables = !this.showActionables;
      if (this.showActionables) {
        this._reloadActionables(1, true);
      }
    },
    toggleFeed() {
      this.showProfile = false;
      this.showActionables = false;
      this.showFeed = !this.showFeed;
      if (this.showFeed) {
        this._reloadNotifications(1, true);
      }
    },
    _reloadActionables(page, clear = false) {
      uspAPI
        .get("/api/notifications/list/?unread=true&prominent=true&page=" + page, {
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
        })
        .then((response) => {
          this.pageActionables = page + 1;
          if (clear) {
            this.allActionables = response.data.results;
          } else {
            this.allActionables = this.allActionables.concat(response.data.results);
          }
        })
        .catch((err) => {
          if (err.response.status == 403) {
            this.$store.dispatch("setConfirmMessage", false);
            this.$router.push({ name: "Error403", params: { id: "NOTIFICATIONS_VIEW_LIST" } });
          } else if (err.response.status == 404) {
            return;
          }
        });
    },
    //
    _reloadNotifications(page, clear = false) {
      uspAPI
        .get("/api/notifications/list/?unread=true&prominent=false&page=" + page, {
          headers: { Authorization: `Bearer ${this.$store.state.accessToken}` },
        })
        .then((response) => {
          this.pageNotifcations = page + 1;
          if (clear) {
            this.allNotifications = response.data.results.map((item) => {
              return { ...item, iconData: this.iconType(item.notification_type) };
            });
          } else {
            this.allNotifications = this.allNotifications.concat(
              response.data.results.map((item) => {
                return { ...item, iconData: this.iconType(item.notification_type) };
              })
            );
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 403) {
            this.$store.dispatch("setConfirmMessage", false);
            this.$router.push({ name: "Error403", params: { id: "NOTIFICATIONS_VIEW_LIST" } });
          } else if (err.response.status == 404) {
            return;
          }
        });
    },
    iconType(value) {
      const newList = [1, 4, 7, 30];
      const returnedList = [14, 16, 18];
      const progressList = [2, 5, 8, 31, 32];
      const completeList = [3, 6, 9, 33];
      const adminList = [10, 0];
      const problemList = [13, 15, 17];
      if (newList.includes(value)) return { iconName: "notification.new", iconClass: "text-green-500 w-10 h-10" };
      if (returnedList.includes(value))
        return { iconName: "notification.returned", iconClass: "text-orange-400 w-10 h-10" };
      if (progressList.includes(value))
        return { iconName: "notification.in_progress", iconClass: "text-yellow-600 w-10 h-10" };
      if (completeList.includes(value))
        return { iconName: "notification.completed", iconClass: "text-green-600 w-10 h-10" };
      if (adminList.includes(value)) return { iconName: "notification.admin", iconClass: "text-red-600 w-10 h-10" };
      if (problemList.includes(value))
        return { iconName: "notification.problem", iconClass: "text-orange-400 w-10 h-10" };
      return { iconName: "notification.problem", iconClass: "text-red-700 w-10 h-10" };
    },
  },
};
</script>
