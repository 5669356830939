module.exports = {
  statusName: function (status) {
    switch (status) {
      case -1:
        return this.$t("statuses.contract_error");
      case 0:
        return this.$t("statuses.new");
      case 1:
        return this.$t("statuses.estimation");
      case 2:
        return this.$t("statuses.payment");
      case 3:
        return this.$t("statuses.manufacturing");
      case 4:
        return this.$t("statuses.delivery");
      case 5:
        return this.$t("statuses.completed");
      case 6:
        return this.$t("statuses.problem");
      case 7:
        return this.$t("statuses.cancelled");
      case 8:
        return this.$t("statuses.draft");
      default:
        return this.$t("statuses.unknown");
    }
  },
  statusIssueName: function (status) {
    switch (status) {
      case 0:
        return this.$t("issue_comment.status_new");
      case 1:
        return this.$t("issue_comment.status_analysis");
      case 2:
        return this.$t("issue_comment.status_pending");
      case 3:
        return this.$t("issue_comment.status_in_progress");
      case 4:
        return this.$t("issue_comment.status_fixed");
      case 5:
        return this.$t("issue_comment.status_closed");
      case 6:
        return this.$t("issue_comment.status_duplicate");
      case 7:
        return this.$t("issue_comment.status_invalid");
      default:
        return this.$t("statuses.unknown");
    }
  },
  statusClasses: function (status) {
    switch (status) {
      case -1:
        return "color-bg-status-err";
      case 0:
        return "color-bg-status-0";
      case 1:
        return "color-bg-status-1";
      case 2:
        return "color-bg-status-2";
      case 3:
        return "color-bg-status-3";
      case 4:
        return "color-bg-status-4";
      case 5:
        return "color-bg-status-5";
      case 6:
        return "color-bg-status-6";
      case 7:
        return "color-bg-status-7";
      case 8:
        return "color-bg-status-8";
      default:
        return "color-bg-status-0";
    }
  },
};
