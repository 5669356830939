<template>
  <div class="main-view">
    <div class="main-container-v1">
      <h1 class="main-title">
        {{ titleKey }}
      </h1>
    </div>
    <div class="title-container-v3">
      <div
        v-if="showButtonBar"
        class="py-3 my-1 mx-auto max-w-8xl flex items-end justify-between">
        <span class="for-button-box-v1">
          <slot name="buttonBar"> </slot>
        </span>
      </div>
      <div
        v-if="goBackButtonLink"
        class="mx-auto py-3 max-w-8xl flex items-end justify-end">
        <span class="for-button-box-v1">
          <RouterLinkButton
            icon-name="action.back"
            :title-attr="$t('actions.go_back')"
            :title-text="$t('actions.go_back')"
            :to="goBackButtonLink" />
        </span>
      </div>
      <div class="flex flex-col">
        <slot name="filter" />
        <div class="main-table-box-v1">
          <div class="table-box-v3">
            <div
              v-if="!table"
              class="white-card">
              <slot></slot>
            </div>
            <div
              v-else
              class="table-card">
              <slot></slot>
            </div>
            <div
              v-if="showPagination"
              class="-mt-1">
              <slot name="pagination"> </slot>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainCard",
  props: {
    titleKey: {
      type: String,
      default: "no key",
      required: false,
    },
    // Show button bar on top component bellow title
    showButtonBar: {
      type: Boolean,
      default: false,
    },
    // Show pagination slot on container bottom
    showPagination: {
      type: Boolean,
      default: false,
    },
    // Give ma a link :to and I show return button on top
    goBackButtonLink: {
      type: String,
      required: false,
      default: "",
    },
    // version for data table
    table: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
