import { createLogger } from "vuex";

const hideMutations = ["updateLoading", "updateFilterNplate", "updateFilterScrap", "updateFilterOrder"];
const hideActions = ["loadingStart", "loadingEnd", "setFilterScrap", "setFilterOrder", "setFilterNplate"];

// url https://vuex.vuejs.org/guide/plugins.html#built-in-logger-plugin

const logger = createLogger({
  collapsed: true, // auto-expand logged mutations
  filter(mutation) {
    // filter(mutation, stateBefore, stateAfter)
    // returns `true` if a mutation should be logged
    // `mutation` is a `{ type, payload }`
    return !hideMutations.includes(mutation.type);
  },
  actionFilter(action) {
    // actionFilter(action, state)
    // same as `filter` but for actions
    // `action` is a `{ type, payload }`
    return !hideActions.includes(action.type);
  },
  transformer() {
    // transform the state before logging it.
    // for example return only a specific sub-tree
    // you can use state attribute and checked value after action
    return "------";
  },
  mutationTransformer(mutation) {
    // mutations are logged in the format of `{ type, payload }`
    // we can format it any way we want.
    return mutation.type;
  },
  actionTransformer(action) {
    // Same as mutationTransformer but for actions
    return action.type;
  },
  logActions: true, // show actions in console
  logMutations: true, // show mutations in console
  logger: console, // implementation of the `console` API, default `console`
});

export default logger;
