import { createI18n } from "vue-i18n";
import enLocale from "./locales/en-US.json";
import plLocale from "./locales/pl-PL.json";
import ruLocale from "./locales/ru-RU.json";

function loadLocaleMessages() {
  const locales = require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function loadLocaleMessagesTest() {
  return {
    en: enLocale,
    pl: plLocale,
    ru: ruLocale,
  };
}

const i18n = createI18n({
  locale: "pl-PL",
  fallbackLocale: "en-US",
  messages: process.env.NODE_ENV !== "test" ? loadLocaleMessages() : loadLocaleMessagesTest(),
});

export default i18n;
