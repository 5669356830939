<template>
  <div
    v-if="!accountStatus.alwaysActive"
    class="flex w-full">
    <div class="ml-0 flex items-center min-w-fit w-full mr-0">
      <div
        v-if="yellowMessage"
        class="flex w-full mr-1 bg-yellow-100 h-full">
        <div class="flex my-auto mr-1 items-center">
          <div class="w-6 mx-0 min-[400px]:mx-2">
            <Icon
              icon-name="icon.warning"
              class-attr="hidden min-[400px]:inline h-6 w-6 text-yellow-800 mx-auto" />
          </div>
          <p class="text-2xs leading-0 font-medium text-yellow-800">
            <span class="sm:inline md:inline lg:inline xl:inline 2xl:inline">
              {{
                $t(permissionStatus ? "expired_account.expired_in_admin" : "expired_account.expired_in", [expiredTime])
              }}
            </span>
          </p>
        </div>
      </div>
      <div
        v-if="redMessage"
        class="flex w-full mr-1 bg-red-100 h-full">
        <div class="flex my-auto mr-1 items-center">
          <div class="w-6 mx-0 min-[400px]:mx-2">
            <Icon
              icon-name="icon.warning"
              class-attr="hidden min-[400px]:inline h-6 w-6 text-red-800 mx-auto" />
          </div>
          <p class="text-2xs leading-0 font-medium text-red-800">
            <span class="sm:inline md:inline lg:inline xl:inline 2xl:inline">
              {{
                $t(permissionStatus ? "expired_account.expired_in_admin" : "expired_account.expired_in", [expiredTime])
              }}
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import Icon from "./icons/Icon.vue";
import "dayjs/locale/pl";
import relativeTime from "dayjs/plugin/relativeTime";
import { checkAccessOneRequired } from "../helpers/arrayHelpers";
dayjs.extend(relativeTime);

export default {
  name: "AccountStatusBarInformation",
  components: {
    Icon,
  },
  data() {
    return {
      pageIsExpired: false,
    };
  },
  computed: {
    accountStatus() {
      return this.$store.getters.accountStatus;
    },
    redMessage() {
      return !this.accountStatus.isExpired && this.accountStatus.showYellowWarning && this.accountStatus.showRedWarning;
    },
    yellowMessage() {
      return (
        !this.accountStatus.isExpired && this.accountStatus.showYellowWarning && !this.accountStatus.showRedWarning
      );
    },
    expiredTime() {
      return this.$store.state.organization_expires ? this.formatDate(this.$store.state.organization_expires) : "";
    },
    permissionStatus() {
      const permissionNeeded = ["PERMISSION_MANAGEMENT", "LICENSE_MANAGEMENT"];
      const myPermission = this.$store.getters.getMyPermission;
      return checkAccessOneRequired(myPermission, permissionNeeded);
    },
  },
  created() {
    if (this.redMessage) setInterval(() => this.checkIsExpired(), 60000);
  },
  methods: {
    formatDate(value) {
      this.$i18n.locale.slice(0, 2) == "pl" ? dayjs.locale("pl") : dayjs.locale("en");
      return dayjs(value).fromNow();
    },
    checkIsExpired() {
      const isExpiredTime = dayjs().isAfter(dayjs(this.$store.state.organization_expires));
      if (isExpiredTime && !this.pageIsExpired) {
        this.$router.go(0);
        this.pageIsExpired = true;
      }
    },
  },
};
</script>
