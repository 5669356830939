import { createStore } from "vuex";
import logger from "./logger";
import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

const developerEnvironment = process.env.VUE_APP_FAVICON === "favicon-local.ico";

export default createStore({
  plugins: developerEnvironment ? [logger] : [],
  state,
  getters,
  mutations,
  actions,
});
